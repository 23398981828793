// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { getMessaging, getToken } from "firebase/messaging";
import {
    getAuth,
    FacebookAuthProvider,
    GoogleAuthProvider,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Web Client_ID
// 144880675044-tpdpcecmjj4u6h4q3jkb5damenr0t7fn.apps.googleusercontent.com

// Web Client_secrete
// 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

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const provider = new FacebookAuthProvider();
const googleProvider = new GoogleAuthProvider();
const messaging = getMessaging(app);

export { auth, messaging, provider, googleProvider };

// FB App ID
// 920042829271854
// FB App Secret
// fde6b3e89bb5be45aa627562b5ccaf05
// https://toctocme-web.firebaseapp.com/__/auth/handler
