// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const firebaseConfig = {
    apiKey: "AIzaSyDj4xMXMEo2QkilNj19tgapebwkJAvPkNQ",
    authDomain: "toctocme-62714.firebaseapp.com",
    databaseURL: "https://toctocme-62714-default-rtdb.firebaseio.com",
    projectId: "toctocme-62714",
    storageBucket: "toctocme-62714.firebasestorage.app",
    messagingSenderId: "117513261909",
    appId: "1:117513261909:web:26c1e66922898d95dfd5dc",
    measurementId: "G-QF6EYEP4VX"
};

// Initialize Firebase
