import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
} from "react-bootstrap";
import ProfileImg from "../assets/img/profile-logo.png";
import UserSidebar from "./UserSidebar";
import toast, { Toaster } from "react-hot-toast";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Skeleton } from "antd";
import { ModalContext } from "./Component/home/Navbar";
import Imgcross from "../assets/img/cross.png";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const MyAccount = () => {
  const { t } = useTranslation();
  const [image, setImage] = useState();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  localStorage.setItem("isVerify", "false");

  useEffect(() => {
    setLoading(true);
    getUser();
  }, []);

  const getUser = async () => {
    let path = apiPath.get_user_profile;
    let options = { method: "POST" };
    try {
      let res = await Http(path, options);
      console.log("c_c", res?.data?.result);
      let { image } = res?.data?.result;
      setImage(image);
      setUser(res?.data?.result);
      setLoading(false);
    } catch (err) {
      toast.error(err.msg);
      setLoading(false);
    }
  };

  const onUpload = (e) => {
    // console.log(e.target.files[0]);
    let img = e.target.files[0];
    const fileRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    console.log(img);
    if (fileRegex.test(img?.name)) {
      uploadImage(img);
    } else {
      toast.error("Please select a valid image format!");
    }
  };

  const uploadImage = async (img) => {
    let formData = new FormData();
    formData.append("files", img);
    let path = apiPath.docUpdate;
    let options = { method: "POST", data: formData };
    if (img !== null && img !== "undefined" && img !== "") {
      try {
        let res = await Http(path, options);
        if (res?.data?.success === true) {
          const { image_url } = res?.data?.result;
          setImage(image_url);
          toast.success(res?.data?.msg);
        } else {
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("something went wrong!");
    }
  };

  const onSubmit = async (values) => {
    let path = apiPath.update_user_profile;
    let formData = new FormData();
    formData.append("user_type", "2");
    formData.append("name", values.username);
    formData.append("email", values.email);
    formData.append("image", image ? image : values.img);
    formData.append("gender", values.gender ? values.gender : user?.gender);
    if (values.country_code.includes("+")) {
      formData.append("country_code", values.country_code);
    } else {
      formData.append(
        "country_code",
        values.country_code ? "+" + values.country_code : user?.country_code
      );
    }
    formData.append("mobile", values?.mobile ? values?.mobile : user?.mobile);
    formData.append(
      "dob",
      user ? moment(values.dob).format("yyyy-MM-DD") : null
    );
    let options = {
      method: "POST",
      data: formData,
    };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        getUser();
      } else {
        toast.success(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster position="top-right" />
      <section className="order-main">
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              <div className="m_sidebar_bg">
                {loading ? (
                  [1, 2, 3].map((item) => <Skeleton active key={item} />)
                ) : (
                  <div className="m-account-cls">
                    <div className="profile_update">
                      <div className="profile_update_bg">
                        <div className="profile-img rounded-circle overflow-hidden mx-auto">
                          {image === "undefined" ||
                            image === "" ||
                            image === "null" ||
                            image === null ? (
                            <img
                              className="w-100 h-100"
                              src={ProfileImg}
                              alt="avatar"
                            />
                          ) : (
                            <img
                              className="w-100 h-100"
                              src={IMAGE_PATH + image}
                              alt="avatar"
                            />
                          )}
                        </div>
                        <div className="profile_cont">
                          <h3>{t("upload_profile_img")}</h3>
                          <div className="profile-upload-btn">
                            <div className="file_input">
                              <span
                                for="file-upload"
                                className="btn btn_primary"
                              >
                                {t("upload")}
                                <input
                                  className="btn btn_primary"
                                  type="file"
                                  name="profile"
                                  id="file-upload"
                                  onChange={(e) => onUpload(e)}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ProfileForm user={user} onSubmit={onSubmit} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

const ProfileForm = ({ onSubmit, user }) => {
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [isVerifyMobile, setIsVerifyMobile] = useState(false);
  const [countryCode, setCountryCode] = useState(user?.country_code);
  const [mobileNo, setMobileNo] = useState(user?.mobile);
  let localVerify = localStorage.getItem("isVerify");
  const initialValues = {
    username: user?.name ? user?.name : "",
    email: user?.email ? user?.email : "",
    mobile: user?.mobile ? user?.mobile : "",
    country_code: user?.country_code ? user?.country_code : "",
    gender: user.gender ? user.gender : "",
    dob: user?.dob ? moment(user?.dob, "DD-MM-YYYY").toDate() : new Date(),
  };

  console.log(initialValues, "initialValues");

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z\s]*$/, "Invalid name"),
    email: Yup.string()
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter valid email"),
    mobile: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .required("Date of birth is required")
      .max(new Date(), "Invalid date of birth"),
  });

  const today = new Date();
  const threeYearsAgo = new Date(
    today.getFullYear() - 3,
    today.getMonth(),
    today.getDate()
  );

  const onOpen = () => {
    setVerifyOtp(true);
  };

  const onClose = (isVerified) => {
    if (isVerified === true) {
      setIsVerifyMobile(false);
      localStorage.setItem("isVerify", true);
    }
    setVerifyOtp(false);
  };

  if (localVerify === true) {
    setIsVerifyMobile(false);
  }

  const excludeCurrentYear = (date) => {
    const currentYear = new Date().getFullYear();
    return date.getFullYear() !== currentYear;
  };

  return (
    <>
      {verifyOtp && (
        <OtpVerification
          open={onOpen}
          close={onClose}
          mobile={mobileNo}
          country={countryCode}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="profile_form">
              <Row>
                <Col md="12">
                  <Form.Group className="form-group">
                    <label>{t("full_name")}</label>
                    <FormControl
                      type="text"
                      onChange={handleChange}
                      placeholder="John"
                      name="username"
                      value={values.username}
                    />
                    <div className="error_msg">
                      <ErrorMessage name="username" />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className="form-group">
                    <label>{t("email_address")}</label>
                    <FormControl
                      type="text"
                      placeholder="Email"
                      onChange={handleChange}
                      name="email"
                      value={values.email}
                    />
                    <div className="error_msg">
                      <ErrorMessage name="email" />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="form-group">
                    <label>D.O.B.</label>
                    <ReactDatePicker
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      dropdownMode="select"
                      name="dob"
                      minDate={new Date()}
                      maxDate={threeYearsAgo}
                      selected={values.dob ? values.dob : ''}
                      filterDate={(date) => {
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        return date.getTime() < today.getTime();
                      }}
                      onChange={(date) => {
                        setFieldValue("dob", new Date(date));
                        console.log("date", new Date(date));
                      }}
                      readOnly
                    />
                    <div className="error_msg">
                      <ErrorMessage name="dob" />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="form-group">
                    <label>{t("mobile_no")}</label>
                    <div className="verify_btn">
                      <PhoneInput
                        inputProps={{
                          name: "mobile",
                          required: true,
                          autoFocus: false,
                        }}
                        country={"fr"}
                        onChange={(value, data) => {
                          console.log(value);
                          let country_code = data.dialCode;
                          let phone = value.slice(data?.dialCode?.length);
                          if (user?.mobile === phone) {
                            setIsVerifyMobile(false);
                          } else {
                            if (value) {
                              setIsVerifyMobile(true);
                            } else {
                              setIsVerifyMobile(false);
                            }
                          }
                          setFieldValue("mobile", phone);
                          setFieldValue("country_code", country_code);
                          setCountryCode(country_code);
                          setMobileNo(phone);
                          console.log("mobile", phone, country_code);
                        }}
                        value={
                          values.country_code + "" + values.mobile
                            ? values.country_code + "" + values.mobile
                            : user?.country_code + " " + user.mobile
                        }
                      />
                      {isVerifyMobile === false || localVerify === false ? (
                        <Button disabled className="btn_black">
                          {t("verified")}
                        </Button>
                      ) : (
                        <Button className="btn_black" onClick={onOpen}>
                          {t("verify")}
                        </Button>
                      )}
                    </div>
                    <div className="error_msg">
                      <ErrorMessage name="mobile" />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className="form-group">
                    <label>{t("gender")}</label>
                    <div className="radio_cls">
                      <label className="custom_radio">
                        <input
                          type="radio"
                          name="gender"
                          value="Male"
                          checked={values.gender === "Male"}
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        {t("male")}
                      </label>
                      <label className="custom_radio">
                        <input
                          type="radio"
                          name="gender"
                          value="Female"
                          onChange={handleChange}
                          checked={values.gender === "Female"}
                        />
                        <span className="checkmark"></span>
                        {t("female")}
                      </label>
                    </div>
                    <div className="error_msg">
                      <ErrorMessage name="gender" />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                type="submit"
                disabled={
                  isVerifyMobile === true || localVerify === false
                    ? true
                    : false
                }
                className="btn_primary"
              >
                {t("save")}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export const OtpVerification = ({ open, close, mobile, country }) => {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [getTimer, setTimer] = useState(0);
  const [otpError, setOtpError] = useState("");
  const [getOtp, setOtp] = useState({ otp: "", hasErrored: false });

  useEffect(() => {
    resendOTP();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  // console.log("mob, cc", mobile, country);

  const onOTPSubmit = async () => {
    if (getOtp.otp == null || getOtp.otp == "" || getOtp.otp?.length < 4) {
      setOtpError("OTP is required!");
    } else if (getOtp.otp !== "1234") {
      setOtpError("OTP is invalid!");
    } else {
      let path = apiPath.verifyOTP;
      let data = {
        type: "profile",
        otp: getOtp.otp,
        verify_type: "mobile",
        mobile: mobile,
        country_code: country.includes("+") ? country : "+" + country,
      };
      let options = {
        method: "POST",
        data,
      };
      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          toast.success(res.data.msg);
          localStorage.setItem("isVerify", true);
          setTimeout(() => {
            close(true);
          }, 1000);
        } else {
          toast.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
        toast.dismiss("Something went wrong!");
      }
    }
  };

  const resendOTP = async () => {
    let path = apiPath.signup_sendOTP;
    var formData = new FormData();
    formData.append("type", "profile");
    formData.append("user_type", "2");
    formData.append("verify_type", "mobile");
    formData.append("mobile", mobile);
    formData.append(
      "country_code",
      country.includes("+") ? country : "+" + country
    );
    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        toast.success(res.data.msg);
        setMinutes(1);
        setSeconds(30);
      } else {
        toast.error(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleChange = (value) => {
    setOtp({ ...getOtp, otp: value });
  };

  return (
    <>
      <Modal
        show={open}
        onHide={close}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            OTP
          </h5>
          <button type="button" className="btn-close" onClick={close}>
            <img src={Imgcross} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_cont">
            <h4>Welcome !</h4>
            <h5>Verify Account</h5>
            <p>Please find OTP here XXXX{mobile?.match(/\d{3}$/)}</p>
          </div>
          <span className="error_msg">{otpError}</span>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <OtpInput
              autoFocus
              required
              value={getOtp.otp}
              onChange={handleChange}
              numInputs={4}
              className="otp-mob-input"
              hasErrored={getOtp.hasErrored}
              isInputNum={true}
              // containerStyle={{'justify-content':'center'}}
              errorMessage={{ required: "Enter OTP " }}
            />
          </Form.Group>
          <div className="otp_timer">
            {seconds > 0 || minutes > 0 ? (
              <p>
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <>
                <p>
                  Didn't get otp?{" "}
                  <Link
                    style={{ color: "#0147AD", font: "bold" }}
                    to="#"
                    onClick={resendOTP}
                  >
                    Resend
                  </Link>{" "}
                </p>
              </>
            )}
          </div>

          <div className="countdown-text"></div>

          <div className="login-btn">
            <button className="continue-btn btn_primary" onClick={onOTPSubmit}>
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyAccount;
