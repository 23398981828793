let appMode = "production";
if (
  window.location.origin === "http://localhost:4000" ||
  window.location.origin === "http://localhost:300"
) {
  appMode = "stage";
}
let newlive = "https://backend.toctocme.com/api/";
let stage = "https://backend.toctocme.com/api/";
export const IMAGE_PATH = "https://backend.toctocme.com";

if (appMode === "production") {
  newlive = "https://backend.toctocme.com/api/";
  // newlive = "http://localhost:2000/api/";
} else {
  stage = "https://backend.toctocme.com/api/";
  // stage = "http://localhost:2000/api/";

}
export const baseURL = appMode === "production" ? newlive : stage;
let pathObj = {
  checkArtisanAddress: "users/address-with-artisan-check",
  signup_sendOTP: "users/send-otp",
  verifyOTP: "users/verify-otp",
  sendOTP: "users/send-otp",
  login: "users/login",
  logot: "users/logout",
  register: "users/update-profile",
  setPassword: "users/verify-otp",
  changePassword: "users/change-password",
  profileInfo: "users/update-profile",
  categoryList: "users/get-category-list",
  homeData: "users/dashboard-data",
  homeBanner: "users/dashboard-banners",
  addAddress: "users/add-address",
  getAddress: "users/get-address",
  updateAddress: "users/update-address",
  deleteAddress: "users/delete-address",
  setDefaultAddress: "users/update-default-address",
  allArtisans: "users/get-artisan-list",
  categoryBasedArtisanList: "users/category-base-artisan",
  forgotPassword: "users/forgot-Password",
  artisanRequest: "users/artisan-request",
  artisanDetails: "users/get-artisan-detail",
  serviceDetails: "users/get-service-details",
  get_user_profile: "users/user-profile",
  update_user_profile: "users/update-profile",
  setPreview: "users/set-preview-checkout",
  setPreviewOffline: "users/set-preview-offline-checkout",
  getPreview: "users/get-preview-checkout",
  confirmCheckout: "users/checkout",
  guest_user: "users/guest-register",
  promo_code_list: "users/get-promocode",
  apply_promo_code: "users/apply-promo-code",
  remove_promo_code: "users/remove-promo-code",
  sub_category_list: "users/get-subcategory-list",
  getActiveCategoryList: "users/get-active-category-list",
  getserviceListCategoryBase: "users/get-service-list-category-base",
  docUpdate: "users/doc-update",
  orderList: "users/order-list",
  orderDetail: "users/order-detail",
  cancelOrder: "users/cancel-order",
  reOrder: "users/re-order",
  getFavList: "users/get-favourite-list",
  getWalletData: "users/get-wallet",
  markFavorite: "users/mark-favorite",
  serviceList: "users/get-service-list",
  notificationList: "users/notification-list",
  readAllNotification: "users/read-all-notifications",
  unreadAllNotification: "users/unread-all-notifications",
  deleteAllNotification: "users/delete-notification",
  deleteNotification: "users/delete-notification",
  getHeavyServiceSteps: "users/get-heavy-steps",
  heavyOrder: "users/heavy-order-api",
  setPreviewOffline: "users/set-preview-offline-checkout",
  payNow: "users/pay-now",
  staticPages: "users/static-pages",
  updateNotification: "users/update-user-notification",
  trackOrder: "users/track-customer",
  reviewOrder: "users/review-order",
  socialLoginSignup: "users/social-login-signup",
  updateOrderStatus: "users/job-start",
  timeSlots: "users/get-slots",
  getSettings: "users/get-settings",
  contactUs: "users/save-contact",
  downloadInvoice: "users/download-receipt",
};
export default pathObj;
