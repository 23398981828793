import axios from "axios";

const Http = async (path, options) => {
  const ln = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";

  // console.log("=====language=====", ln);   3.20.147.34
  try {
    let BaseURL = "https://backend.toctocme.com/api/" + path;
    // let BaseURL = "http://localhost:2000/api/" + path;
    let token = localStorage.getItem("userToken")
      ? localStorage.getItem("userToken")
      : localStorage.getItem("guestToken");
    if (token) {
      options.headers = {
        "x-access-token": token,
        Authorization: `Bearer ${token}`,
        "Accept-Language": "en",
        Language: `${ln}`,
      };
    }
    options.url = BaseURL;
    let result = await axios(options);
    return result;
  } catch (error) {
    if (error.code === "ERR_BAD_REQUEST") {
      let errorss = error.response.status;
      if (errorss === 401) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
      } else if (errorss === 409) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
      } else if (errorss === 404) {
        let response = {};
        response.statusText = error.message;
        response.status = 404;
        return response;
      } else {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
      }
    } else {
      let response = {};
      response.statusText = error.message;
      response.status = 504;
      return response;
    }
  }
};
export default Http;
